<template>
	<!-- Raster Layers -->
	<div class="settingsmappanel d-flex flex-column">
		<div class="d-flex flex-column pa-3 bottomborder" ref="header">
			<div class="settings-title fw-bold">{{ $t('panels.configpannel.satelliteimage') }}</div>
			<div class="settings-subtitle fw-regular fs-sm">{{ $t('panels.configpannel.subtitle') }}</div>
		</div>
		<raster-tools class="px-3" :rastertools="'rastertoolsCompareMap1'" ref="rastertools" />
		<div style="max-height: calc(100vh - 255px); overflow-y: scroll" class="px-4">
			<layers-raster-list :rasterlist="rasterId" :key="0" />
		</div>
		<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
	</div>
</template>

<script>
import RasterTools from './RasterTools';
import LayersRasterList from './LayersRasterList';
import constants from '@/utils/constants';

export default {
	data() {
		return {
			rasterId: constants.RASTER.SETTINGS
		};
	},
	components: {
		RasterTools,
		LayersRasterList
	},
	computed: {
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		}
	}
};
</script>

<style></style>
